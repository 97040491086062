import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import PageHeader from "../PageHeader/page-header";
import NucleonDataGrid from "../NucleonDataGrid/nucleon-data-grid";
import NucleonButton from "../NucleonButton/nucleon-button";
import { Tooltip, Typography } from "@mui/material";
import { getGridNumericOperators, getGridStringOperators } from "@mui/x-data-grid";

function RadiationTestDataTable() {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isFirstLoad = useRef(true);

  // Initialize filter model from URL parameters only on initial load
  useEffect(() => {
    if (isFirstLoad.current) {
      const params = queryString.parse(location.search);
      const items = Object.entries(params).map(([key, value]) => {
        const [field, operator] = key.split('_');
        return {
          field,
          value,
          operator: operator || 'contains',
        };
      });
      setFilterModel({ items });
      isFirstLoad.current = false;
    }
  }, []); // Empty dependency array ensures this runs only once

  // Update URL parameters when filter model changes
  useEffect(() => {
    const currentFilters = filterModel.items.reduce((acc, item) => {
      acc[`${item.field}_${item.operator}`] = item.value;
      return acc;
    }, {});
    const queryStringParams = queryString.stringify(currentFilters);

    // Update the URL without causing a re-render
    navigate(`?${queryStringParams}`, { replace: true });
  }, [filterModel, navigate]);

  const column_config = [
    {
      field: "details",
      headerName: "Details",
      width: 150,
      sortable: false,
      headerFilters: false,
      filterable: false,
      renderCell: (params) => (
        <NucleonButton
          isActive={true}
          handleButtonClick={() =>
            navigate(
              `/dashboard/parts/${encodeURIComponent(params.row.Manufacturer)}/${encodeURIComponent(params.row["Part Number"])}`
            )
          }
        >
          Details
        </NucleonButton>
      ),
    },
    {
      field: "Part Number",
      headerName: "Part Number",
      width: 220,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: "Manufacturer",
      headerName: "Manufacturer",
      width: 200,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: "Category",
      headerName: "Category",
      width: 250,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    },
    {
      field: "Description",
      headerName: "Description",
      width: 300,
      filterable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.Description}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "default",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {params.row.Description}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "Max Passing TID",
      headerName: "Max Passing TID",
      width: 220,
      filterable: true,
      filterOperators: getGridNumericOperators().filter(
        (operator) => operator.value === ">" || operator.value === "<"
      ),
    },
    {
      field: "Max Passing LET",
      headerName: "Max Passing LET",
      width: 220,
      filterable: true,
      filterOperators: getGridNumericOperators().filter(
        (operator) => operator.value === ">" || operator.value === "<"
      ),
    },
    {
      field: 'Data Source',
      headerName: 'Data Source',
      width: 220,
      filterable: true,
      filterOperators: getGridStringOperators().filter((operator) => operator.value === 'contains'),
    }
  ];

    // Fetch data from the server with pagination and filters
    const fetchData = useCallback(async () => {
      const { page, pageSize } = paginationModel;
      const filters = filterModel.items.reduce((acc, item) => {
        acc[item.field] = {
          value: item.value,
          operator: item.operator,
        };
        return acc;
      }, {});
  
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dashboard/datatable`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            page,
            size: pageSize,
            filters,
          }),
          credentials: "include",
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        setRows(data.rows);
        setTotalRows(data.total);
      } catch (error) {
        console.error(error);
      }
    }, [paginationModel, filterModel]);
  
    useEffect(() => {
      fetchData();
    }, [paginationModel, filterModel, fetchData]);
  
    return (
      <Box
        sx={{
          width: "100%",
          height: `calc(100vh - ${90}px)`,
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <PageHeader
          title="Radiation Test Data Table"
          breadcrumbs={[{ label: "Dashboard", href: "/dashboard" }]}
          showButton={false}
        />
        <NucleonDataGrid
          rows={rows}
          headerFilters
          columns={column_config}
          total={totalRows}
          getRowId={(row) => row["Part Number"]}
          showToolbar
          setPaginationModel={setPaginationModel}
          paginationModel={paginationModel}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
        />
      </Box>
    );
  }
  
  export default RadiationTestDataTable;